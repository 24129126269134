import React from 'react'
import { Spin } from 'antd'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import TasksList from './component/tasksList.js'
import TaskViewOrEdit from './component/taskViewOrEdit/index.js'
import './index.less'

const MonitorNavigation = Loader.loadBusinessComponent('MonitorNavigation')
const NoData = Loader.NoData

@withRouter
@Decorator.withEntryLog()
@observer
class MonitorTaskContainer extends React.Component {
  constructor(props){
    super(props)
    this.initTime = true
    this.state = {
      taskTypes: [props.taskType],//101501-黑名单 101502-未知人员布控 101503-魅影 101504-专网
      isLoading: true,
      item: undefined,// 当前列表选中的任务
      EidtKey: Math.random(),
      libType: props.libType // 布控任务
    }
  }

  /**
   * @desc 改变loading状态 ---布控任务查询详情loading
   * @param {boolean} state loading状态
   */
  changeLoadingState = (state) => {
    this.initTime = !state ? false : this.initTime
    this.setState({
      isLoading: state
    })
  }

  /**
   * @desc 编辑保存布控任务后更新列表和当前选中的布控任务
   */
  updateMonitorTasksList = () => {
    this.taskListRef.getTaskList({},(res) => {
      let list = res.list
      let item = list[0] || {}
			item.id && this.taskListRef.setItemById(item.id).then(res => {
        this.setState({
          EidtKey: Math.random()
        })
      })
    })
  }

  /**
   * @desc 设置当前选中的布控任务
   * @param {object} item 单个布控任务详细信息
   */
  setItem = (item={}) => {
    this.setState({
      item,
      libId: item.id
    })
  }

  changeLibId = (libId) => {
    this.setState({
      libId
    })
  }

  render() {
    const { item, isLoading } = this.state;
    const { currentMenu, moduleName, taskType, libType } = this.props;
    return (
      <MonitorNavigation
        libType={libType}
        currentMenu={currentMenu}
      >
        <div className='monitor-content-aside'>
          <TasksList 
            changeLibId={this.changeLibId}
            activeItem={item || {}}
            changeLoadingState={this.changeLoadingState}
            setItem={this.setItem}
            refDom={taskListRef => this.taskListRef = taskListRef}
            taskTypes={[taskType]}
            libType={libType}
            moduleName={moduleName}
          />
        </div>
        <div className='monitor-content-wrapper' key={this.state.libId}>
          <Spin spinning={isLoading} size='normal'>
            { isLoading && this.initTime 
              ? null 
              : (!item || !item.name) 
                ? <NoData title={'暂无数据'} imgType={2}/> 
                : <TaskViewOrEdit
                    item={item}
                    taskType={taskType}
                    libType={libType}
                    actionName={moduleName}
                    updateMonitorTasksList={this.updateMonitorTasksList}
                    key={this.state.EidtKey}
                  />
            }
          </Spin>
        </div>
      </MonitorNavigation>
    )
  }
}

export default MonitorTaskContainer