import React, { Component } from 'react'
import './index.less'
const IconFont = Loader.loadBaseComponent('IconFont')
const AuthComponent = Loader.loadBusinessComponent('AuthComponent')
const AuthComponentArray = ['keyPersonnelTaskManage', 'outsiderTaskManage','eventTaskManage','privateNetTaskManage','epidemicPersonTrackTaskManage'] // 权限字段

class TaskItem extends Component{
  /**
   * @desc 判断布控任务状态
	 * @param {object} item 单个布控任务信息
	 * @param {boolean} isClass 返回className还是文字
   */
	taskTypeStr = (item, isClass) => {
    let res = ''
    switch(item.taskStatus){
      case 0 :
        res = isClass ? 'state be-paused' : '已暂停'
        break;
      case 1 :
        res = isClass ? 'state be-running' : '运行中'
        break;
      case 2 :
        res = isClass ? 'state out-of-date' : '未开始'
        break;
      case 3 :
        res = isClass ? 'state out-of-date' : '已过期'
        break;
      case 4 :
        res = isClass ? 'state be-deleted' : '已删除'
        break;
      default: 
        break;
    }
    return res
  }
  
  /**
   * @desc 查看布控任务详情
   */
  getDetailLib = id => {
    const { activeItem, setItemById } = this.props;
    if (activeItem.id !== id && setItemById) {
      setItemById(id)	
    }
  }

   /**
   * @desc 删除布控任务
   */
  delTasksModal = (e, item) => {
    e.stopPropagation();
    const { delTasksModal, activeItem } = this.props;
    const data = {
      id: item.id, 
      isActive: item.id === activeItem.id, 
      name: item.name
    }
    delTasksModal && delTasksModal(data)
  }

   /**
   * @desc 开始暂停布控任务
   */
  onStartPauseBtnClick = (e, item) => {
		e.stopPropagation();
    const { onStartPauseBtnClick } = this.props;
    onStartPauseBtnClick && onStartPauseBtnClick(e, item)
  }

  render(){
    const { item, activeItem, libType } = this.props
    return (
      <div
        className={item.id === activeItem.id ? 'task-item active' : 'task-item'}
        onClick={() => this.getDetailLib(item.id)}
      >
        <div className="title-name">
          <span className='title-tl' title={item.name}>{item.name}</span>
        </div>
        <div className="btn-message">
          <span className={this.taskTypeStr(item, true)}></span>
          <span>{this.taskTypeStr(item, false)}</span>
          <AuthComponent actionName={AuthComponentArray[libType - 1]}>
            <IconFont 
              type="icon-S_Edit_Delete"
              className="del_task"
              title='删除任务'
              onClick={(e) => this.delTasksModal(e, item) }
            />
          </AuthComponent>
          
          {(item.taskStatus === 0||item.taskStatus === 1) && item.canOperate !== 0 && (
            <AuthComponent actionName={AuthComponentArray[libType - 1]}>
              <IconFont 
                type={item.type === '1' ? 'icon-S_View_PlayerPause' : 'icon-S_View_PlayerPlay'}
                title={item.type === '1' ? '暂停任务' : '开启任务'}
                onClick={(e) => this.onStartPauseBtnClick(e, item)}
                className="stop_or_play_icon"
              />
            </AuthComponent>
          )}
        </div>
      </div>
    )
  }
}
export default TaskItem