import React from 'react'
import { observer, inject } from 'mobx-react'
import { message, Form } from 'antd'
import moment from 'moment'
import LibsList from '../libsList'
import TasksReceive from '../tasksReceive'
import BasicInfo from '../basicInfo'
import TasksScope from '../tasksScope'
import FormBtn from '../formBtn'
import './index.less'

//-基本信息--布控库添加--任务范围--任务接收
const MonitorNavigation = Loader.loadBusinessComponent('MonitorNavigation')
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')

@Form.create()
@inject('tab')
@observer
class MonitorTaskAddContainer extends React.Component {
  constructor(props){
    super(props)
    this.libType = props.libType
    this.infoModal = true
    this.state = {
      taskData: {
        taskType: props.taskType, //101501-黑名单 101502-未知人员布控 101503-魅影
        name:'', // 布控任务名称
        description:'', // 布控任务描述
        validTime: moment(new Date()), // 开始时间
        invalidTime: moment().add('days',3), // 结束时间
        captureStartTime: '00:00:00', // 开始抓拍时间
        captureEndTime: '23:59:59', // 结束抓拍时间
        libIds: [], // 布控库id[string]
        deviceIds: [], // 布控范围
        alarmMode: this.libType == 3 ? ['1'] : ['1', '2'], // 报警方式
        repeatMode: '8', // 重复方式
        acceptAlarmUserIds: [BaseStore.user.userInfo.id], // 告警接收人员
        alarmThreshold: props.alarmThreshold // 告警阈值 重点-85  外来-80  魅影-无 一体机-85
      },
      errorShow: false,
      isShowModal: false
    }
  }
  
  /**
   * @desc 组件数据收集
   * @param {object} obj 要修改的字段集合
   */
  changeTasksData = (obj) => {
    let taskData = Object.assign({}, this.state.taskData, obj)
    this.setState({ taskData })
  }

  toViewTaskList = () => {
    //跳转添加布控任务列表界面
		const { tab, taskListModuleName } = this.props
    tab.goPage({
      moduleName: taskListModuleName,
      isUpdate: true,
    })
  }

  componentWillUnmount(){
    clearTimeout(this.setTimer)
  }

  /**
   * @desc 数据提交
   */
  onSubmit = () => {
    const { form } = this.props;
    form.validateFields((err,data) => {
      if(err){
        this.infoModal && message.error('表单验证失败');
        this.infoModal = false;
        clearTimeout(this.setTimer)
        this.setTimer = setTimeout(() => {
          this.infoModal = true;
        }, 3000)
				return
      }
      let taskData = Object.assign({}, this.state.taskData, data)
      //对收集的数据进行处理
      taskData.validTime = taskData.validTime * 1
			taskData.invalidTime = taskData.invalidTime * 1
      if(taskData.alarmMode){
        taskData.alarmMode = taskData.alarmMode.join(',')
      }
      // 对相机id进行处理
      let deviceIds = taskData.deviceIds.map(v => v.id);
      taskData.deviceIds = deviceIds;
      Service.monitorTask.addMonitorTask(taskData).then(res => {
        if(res && res.code === 0){
          message.success('添加成功')
          this.toViewTaskList() // 添加成功后跳转
        }
      }).catch(err => {
        message.error(err.message || '添加失败')
      })
    })
  }

  cancleAddTask = () => {
    this.setState({
      isShowModal: true
    })
  }
  
  handleCancel = () => {
    this.setState({
			isShowModal: false
		})
  }

  render() {
    const { taskData, errorShow, isShowModal } = this.state;
    const { form, taskListModuleName, libLabel, taskLabel } = this.props;
    return (
      <MonitorNavigation
        libType={this.libType}
        currentMenu={taskListModuleName}
      >
        <div className='monitor-content-wrapper monitee-tasks-add'>
          <div className="task-top">
            <h1 className='tasks-add-title'>{`新建${taskLabel}布控任务`}</h1>
          </div>
          <Form autocomplete="off">
            <h2 className='header-step-title'>基本信息</h2>
            <BasicInfo 
              itemDate={taskData}
              changeTasksData={this.changeTasksData}
              form={form}
              libType={this.libType}
            /> 
            {this.libType !== 3 && (
              <div>
                <h2 className='header-step-title'>{libLabel || taskLabel}</h2>
                <LibsList 
                  errorShow={errorShow}
                  itemDate={taskData}
                  changeTasksData={this.changeTasksData}
                  form={form}
                  libType={this.libType}
                />   
              </div>
            )}  
            <h2 className='header-step-title'>任务范围</h2>
            <TasksScope 
              errorShow={errorShow}
              itemDate={taskData}
              changeTasksData={this.changeTasksData}
              form={form}
            />  
            <h2 className='header-step-title'>任务接收</h2>
            <TasksReceive 
              itemDate={taskData}
              errorShow={errorShow}
              changeTasksData={this.changeTasksData}
              form={form}
              libType={this.libType}
            />
            <FormBtn 
              cancleSubmit={this.cancleAddTask}
              toSubmit={this.onSubmit}
            />
          </Form>
          <ConfirmComponent
            title="提示"
            visible={isShowModal}
            onCancel={this.handleCancel}
            onOk={this.toViewTaskList}
            className='monitor-tasks-model'
            img='warning'
          >
            <div className='model-content'>
              <div className="title-name">
                确定取消操作吗?
              </div>
            </div>
          </ConfirmComponent>
        </div>
      </MonitorNavigation>
    )
  }
}

export default MonitorTaskAddContainer

