(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("antd"), require("_"), require("moment"), require("mobxReact"), require("ReactRouterDOM"), require("mobx"));
	else if(typeof define === 'function' && define.amd)
		define("MonitorTasks", ["React", "antd", "_", "moment", "mobxReact", "ReactRouterDOM", "mobx"], factory);
	else if(typeof exports === 'object')
		exports["MonitorTasks"] = factory(require("React"), require("antd"), require("_"), require("moment"), require("mobxReact"), require("ReactRouterDOM"), require("mobx"));
	else
		root["MonitorTasks"] = factory(root["React"], root["antd"], root["_"], root["moment"], root["mobxReact"], root["ReactRouterDOM"], root["mobx"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__) {
return 