import React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { message, Collapse, Button, Form } from 'antd'
import LibsListView from '../../../libsListView'
import './index.less'

// 查看组件
const BasicInfoView = Loader.loadModuleComponent('MonitorTasks', 'BasicInfoView')
const TasksScopeView = Loader.loadModuleComponent('MonitorTasks', 'TasksScopeView')
const TasksReceiveView = Loader.loadModuleComponent('MonitorTasks', 'TasksReceiveView')
const FormBtn = Loader.loadModuleComponent('MonitorTasks', 'FormBtn')

// 编辑组件
const BasicInfoEdit = Loader.loadModuleComponent('MonitorTasks', 'BasicInfo')
const LibsListEdit = Loader.loadModuleComponent('MonitorTasks', 'LibsList')
const TasksScopeEdit = Loader.loadModuleComponent('MonitorTasks', 'TasksScope')
const TasksReceiveEdit = Loader.loadModuleComponent('MonitorTasks', 'TasksReceive')

// 组件注入
const IconFont = Loader.loadBaseComponent('IconFont')
const AuthComponent = Loader.loadBusinessComponent('AuthComponent')
const ConfirmComponent = Loader.loadBaseComponent('ConfirmComponent')
const Panel = Collapse.Panel

@Form.create()
@Decorator.businessProvider('device')
@observer
class MonitorTasksViewAndEdit extends React.Component {
  constructor(props) {
    super(props)
    this.infoModal = true
    this.state = {
      taskData: {
        taskType: +props.taskType // 黑名单
      }, //布控任务信息
      componentIsEdit: false, // 是否是编辑转态
      activeKey: ['0'], // 默认展开的信息
      initTaskData: {}, // 布控初始值，当编辑取消时使用
      errorShow: false, // 表单验证
      isShowModal: false, // 编辑二次确认弹框
      libType: props.libType
    }
  }

  componentDidMount() {
    this.dealItemData()
  }

  /**
   * @desc 对传入的说数据进行处理
   */
  dealItemData = () => {
    const { item, libType } = this.props
    if (!item || !item.name) {
      return
    }
    // 对device进行处理
    let devices = item.devices || []
    let cameraList = this.props.device.cameraList || []
    let deviceArr = _.intersectionBy(cameraList, devices, 'id')
    // 对告警接收人员进行处理
    let acceptAlarmUsers = item.acceptAlarmUsers || []
    let acceptAlarmUserIds = acceptAlarmUsers.map(item => item.id)
    let taskData = {
      id: item.id,
      name: item.name,
      validTime: moment(new Date(item.validTime * 1)),
      invalidTime: moment(new Date(item.invalidTime * 1)),
      captureStartTime: item.captureStartTime ? item.captureStartTime : '00:00:00', //兼容之前布控任务
      captureEndTime: item.captureEndTime ? item.captureEndTime : '23:59:59',
      description: item.description ? item.description : '', //兼容之前布控任务
      alarmThreshold: item.alarmThreshold ? item.alarmThreshold : 80, //兼容之前布控任务
      deviceIds: deviceArr, // 设备的详细信息，提交的时候进行处理
      acceptAlarmUserIds, // 告警人员id[string]
      alarmMode: item.alarmMode && item.alarmMode.length > 0 ? item.alarmMode.split(',') : ['1', '2'],
      repeatMode: item.repeatMode ? item.repeatMode : '8' //兼容之前布控任务
    }
    // 人员入侵没有布控库
    if (libType !== 3) {
      // 对布控库id进行处理
      let libIds = item.libs || []
      libIds = libIds.map(item => item.id)
      taskData.libIds = libIds // 布控库id[string]
    }
    this.setState({
      taskData,
      initTaskData: _.cloneDeep(taskData)
    })
  }

  /**
   * @desc 手风琴头部定义
   */
  collapseHeader = index => {
    const { libType } = this.props
    const sceneCode = BaseStore.user.appInfo.sceneCode
    const monitorLabel = Dict.map.monitorLabel[sceneCode]
    let libLabel
    switch (libType) {
      case 1:
        libLabel = monitorLabel.keyPerson.label
        break
      case 2:
        libLabel = monitorLabel.outsider.label
        break
      default:
        libLabel = '布控人员'
        break
    }
    let title = ['基本信息', libLabel, '任务范围', '任务接收']
    return (
      <div className="colla-header">
        <div className="left-title">
          <div className="colla-left">{title[index]}</div>
        </div>
        <div className="right-icon" onClick={this.changeActive.bind(this, index + '')}>
          <IconFont type={~this.state.activeKey.indexOf(index + '') ? 'icon-S_Arrow_SmallUp' : 'icon-S_Arrow_SmallDown'} />
        </div>
      </div>
    )
  }

  /**
   * @desc 展开手风琴菜单
   */
  changeActive = index => {
    let activeKey = []
    if (~this.state.activeKey.indexOf(index)) {
      // 存在--剔除
      activeKey = _.without(this.state.activeKey, index)
    } else {
      // 不存在--追加
      activeKey = this.state.activeKey.concat(index)
    }
    this.setState({
      activeKey
    })
  }

  showEditComponent = () => {
    // 编辑转态下默认展开第一个组件
    let activeKey = ['0']
    this.setState({
      activeKey,
      componentIsEdit: true
    })
  }

  /**
   * @desc 取消编辑
   */
  cancleSubmit = () => {
    this.setState({
      isShowModal: true
    })
  }

  /**
   * @desc 二次确认
   */
  handleCancel = () => {
    this.setState({
      isShowModal: false,
      activeKey: ['0']
    })
  }

  handleOk = () => {
    let initTaskData = this.state.initTaskData
    this.setState({
      taskData: initTaskData,
      componentIsEdit: false,
      isShowModal: false,
      activeKey: ['0']
    })
  }

  componentWillUnmount() {
    clearTimeout(this.setTimer)
  }

  /**
   * @desc 确认修改
   */
  toSubmit = () => {
    this.props.form.validateFields((err, data) => {
      if (err) {
        this.infoModal && message.error('表单验证失败')
        this.infoModal = false
        clearTimeout(this.setTimer)
        this.setTimer = setTimeout(() => {
          this.infoModal = true
        }, 3000)
        return
      }
      let taskData = Object.assign({}, this.state.taskData, data)
      //对收集的数据进行处理
      taskData.validTime = taskData.validTime * 1
      taskData.invalidTime = taskData.invalidTime * 1
      if (taskData.alarmMode) {
        taskData.alarmMode = taskData.alarmMode.join(',')
      }
      // 对相机id进行处理
      let deviceIds = []
      for (let i = 0; i < taskData.deviceIds.length; i++) {
        deviceIds.push(taskData.deviceIds[i].id)
      }
      taskData.deviceIds = deviceIds
      // 提交数据
      const taskType = this.props.taskType
      Service.monitorTask
        .updateMonitorTask(taskData, taskType)
        .then(res => {
          if (res && res.code === 0) {
            message.success('修改成功')
            // 调用接口，更新列表和单个任务详情
            this.props.updateMonitorTasksList && this.props.updateMonitorTasksList()
          }
        })
        .catch(err => {
          message.error(err.message || '修改失败')
        })
    })
  }

  /**
   * @desc 从编辑子组件修改的数据
   */
  changeTasksData = obj => {
    let taskData = Object.assign({}, this.state.taskData, obj)
    this.setState({ taskData })
  }
  render() {
    const { taskData, componentIsEdit, libType, activeKey, errorShow, isShowModal } = this.state
    const { item, actionName } = this.props
    return (
      <div className="monitor-task-view-edit-container">
        <div className="taks-full-top">
          <div className="task-top">
            <span title={taskData.name} className="title">
              {taskData.name}
            </span>
            {!componentIsEdit && (
              <AuthComponent actionName={actionName}>
                <Button onClick={this.showEditComponent}>
                  <IconFont type="icon-S_Edit_Edit" />
                  编辑
                </Button>
              </AuthComponent>
            )}
          </div>
        </div>
        <div className="view-edit-box">
          <Form autocomplete="off">
            <div className="task-form-box">
              <Collapse bordered={false} activeKey={activeKey}>
                <Panel header={this.collapseHeader(0)} key="0">
                  {componentIsEdit ? (
                    <div>
                      <BasicInfoEdit itemDate={taskData} changeTasksData={this.changeTasksData} form={this.props.form} libType={libType} />
                    </div>
                  ) : (
                    <BasicInfoView item={item} libType={libType} />
                  )}
                </Panel>
                {libType !== 3 && (
                  <Panel header={this.collapseHeader(1)} key="1">
                    {componentIsEdit ? (
                      <div>
                        <LibsListEdit
                          itemDate={taskData}
                          errorShow={errorShow}
                          changeTasksData={this.changeTasksData}
                          form={this.props.form}
                          libType={libType}
                          item={item}
                        />
                      </div>
                    ) : (
                      <LibsListView item={item} libType={libType} />
                    )}
                  </Panel>
                )}
                <Panel header={this.collapseHeader(2)} key="2" className="area-camera-list">
                  {componentIsEdit ? (
                    <div>
                      <TasksScopeEdit errorShow={errorShow} changeTasksData={this.changeTasksData} itemDate={taskData} form={this.props.form} />
                    </div>
                  ) : (
                    <TasksScopeView item={taskData} />
                  )}
                </Panel>
                <Panel header={this.collapseHeader(3)} key="3">
                  {componentIsEdit ? (
                    <div>
                      <TasksReceiveEdit
                        itemDate={taskData}
                        errorShow={errorShow}
                        changeTasksData={this.changeTasksData}
                        form={this.props.form}
                        libType={libType}
                      />
                    </div>
                  ) : (
                    <TasksReceiveView item={item} libType={libType} />
                  )}
                </Panel>
              </Collapse>
              {componentIsEdit && <FormBtn cancleSubmit={this.cancleSubmit} toSubmit={this.toSubmit} />}
            </div>
          </Form>
        </div>
        <ConfirmComponent
          title="提示"
          visible={isShowModal}
          onCancel={this.handleCancel}
          onOk={this.handleOk}
          className="monitor-tasks-model"
          img="warning"
        >
          <div className="edit-model">
            <div className="model-content">确定要取消编辑吗？已经编辑的数据将不会保存。</div>
          </div>
        </ConfirmComponent>
      </div>
    )
  }
}

export default MonitorTasksViewAndEdit
